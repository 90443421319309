/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    artcDetail: {},
  },
  mutations: {
    setArtcDetail(state, payload) {
      console.log(payload)
      state.artcDetail = payload
    },
  },
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/article/getArticleCateList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/article/editArticleCate', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/article/addArticleCate', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoices(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/article/deleteArticleCateList', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
